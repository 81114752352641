import React, { useState } from 'react';
import Logo from '../../assets/images/logo.svg';
import { NavbarMenu } from '../../mockData/navData';
// import { CiSearch } from 'react-icons/ci';
import { MdMenu, MdClose } from 'react-icons/md';
import ResMenu from './ResponsiveMenu';

const Navbar = () => {
    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            toggleMenu();
        }
    };

    return (
        <>
            <nav>
                <div className="container mx-auto px-4">
                    <div className="flex justify-between items-center py-1">
                        {/* Logo Section */}
                        <div>
                            <img src={Logo} alt="Logo" />
                        </div>
                        {/* Menu Section */}
                        <div className="hidden md:block">
                            <ul className="flex items-center gap-6 text-bold">
                                {NavbarMenu.map((item) => (
                                    <li key={item.id}>
                                        <a
                                            href={item.link}
                                            className="inline-block py-1 px-3 hover:font-bold hover:text-primary">
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* Icon Section */}
                        <div className="flex items-center gap-2">
                            {/* <button className="text-2xl hover:bg-primary hover:text-white rounded-full p-2 duration-200">
                                <CiSearch className="text-2xl" />
                            </button> */}
                            <button className="hover:bg-primary hover:text-white font-semibold rounded-md border-2 border-primary px-6 py-2 duration-200 hidden md:block">
                                Stay Updated
                            </button>
                        </div>
                        {/* Mobile Hamburger Section */}
                        <div
                            className="md:hidden"
                            onClick={toggleMenu}
                            onKeyDown={handleKeyDown}
                            role="button"
                            tabIndex={0}>
                            {open ? (
                                <MdClose className="text-4xl" />
                            ) : (
                                <MdMenu className="text-4xl" />
                            )}
                        </div>
                    </div>
                </div>
                {/* Mobile Sidebar Section */}
                <div className={`${open ? 'block' : 'hidden'} md:hidden`}>
                    <ResMenu open={open} />
                </div>
            </nav>
        </>
    );
};

export default Navbar;
