import React from 'react';
import '../../index.css';
import credential from '../../assets/images/credential.png';
import grow from '../../assets/images/grow.png';
import connections from '../../assets/images/connections.png';
import backgroundImg from '../../assets/images/image21.png';
import PhoneImage from '../../assets/images/iPhone 15.png';

const EmpowerCareerJourney = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    };

    return (
        <div className="flex items-center justify-center" style={backgroundImageStyle}>
            <div className="w-fit mx-auto flex flex-col md:flex-row justify-center items-stretch space-x-2 space-y-2 md:space-y-0 mt-[124px]">
                <div className="flex-none">
                    <img
                        className="hidden md:block md:w-[270px] md:h-[500px] lg:w-[400px]"
                        decoding="async"
                        src={PhoneImage}
                        alt=""
                        sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 768px, 100vw"
                    />
                </div>
                <div className="flex flex-col flex-grow space-y-4 md:w-[500px] lg:w-[914px]">
                    <div className="text-center md:text-center">
                        <p className="primary-heading mb-2 sm:mt-4 text-[32px] font-semibold">
                            Empower Your Career Journey
                        </p>
                        <p className="text-2xl font-bold mb-4">
                            Your skills, your opportunities, your future
                        </p>
                        <p className="text-l mb-4">
                            Unlock opportunities with Lillup’s Talent Passport. Our blockchain
                            protocol and AI tech put your skills on the global stage.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-1 mx-auto  md:w-auto lg:w-[679px] ">
                        <div
                            className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2 bg-white sm:w-328 lg:w-[213px]"
                            style={{ width: '96%' }}>
                            <div className="float-left mx-auto md:mx-0">
                                <img src={credential} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className=" text-center md:text-center lg:text-left">
                                <span className="block text-2xl font-bold mb-2">
                                    Authentic Credentials
                                </span>
                                <span className="block">
                                    Elevate your skillset with verifiable credentials.
                                </span>
                            </div>
                        </div>
                        <div
                            className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2 bg-white"
                            style={{ width: '96%' }}>
                            <div className="float-left mx-auto md:mx-0">
                                <img src={grow} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-center md:text-center lg:text-left">
                                <span className="block text-2xl font-bold mb-2">
                                    Continuous Growth
                                </span>
                                <span className="block">
                                    Tailor your learning journey to your career goals.
                                </span>
                            </div>
                        </div>
                        <div
                            className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2 bg-white"
                            style={{ width: '96%' }}>
                            <div className="float-left mx-auto md:mx-0">
                                <img src={connections} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-center md:text-center lg:text-left">
                                <span className="block text-2xl font-bold mb-2">
                                    Worldwide Connections
                                </span>
                                <span className="block">
                                    Link up with leading employers around the globe.
                                </span>
                            </div>
                        </div>
                        <div className="mt-4 pt-6 text-center lg:text-left">
                            <button className="bg-red-600 text-white py-2 px-4 rounded-md">
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmpowerCareerJourney;
