import React from 'react';
import '../../index.css';
import MacBookImage from '../../assets/images/MacBook Pro 16.png';
import verfication from '../../assets/images/verification.png';
import savings from '../../assets/images/savings.png';
import talent from '../../assets/images/talent.png';

const RefineTalent = () => {
    return (
        <div className="flex items-center justify-center h-fit mt-[124px]">
            <div className="w-fit mx-auto flex justify-center items-stretch space-x-2">
                <div className="flex flex-col flex-grow space-y-4 lg:w-[855px] lg:left-[30px]">
                    <div className="text-center">
                        <p className="text-[32px] font-semibold mb-2 ">
                            Refine How You Find Talent
                        </p>
                        <p className="text-2xl font-bold mb-4">
                            Acquire the right skills for your business, effortlessly
                        </p>
                        <p className="text-l mb-4">
                            Revolutionise hiring with Lillup, Unearth verified talent quickly,
                            cutting costs and streamlining recruitment.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mx-auto ">
                        <div className="border border-red-600 shadow-lg p-2 rounded-lg w-[328px] md:w- auto mx-auto md:mx-0 grid grid-rows-2 md:w-[180px] lg:w-[213px]">
                            <div className="float-left mx-auto md:mx-0">
                                <img src={verfication} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-center ">
                                <span className="block text-2xl font-bold mb-2">
                                    Skill Verification
                                </span>
                                <span className="block">
                                    Find confidently with blockchain-secured credentials.
                                </span>
                            </div>
                        </div>
                        <div className="border border-red-600 shadow-lg p-2 rounded-lg w-[328px] mx-auto md:mx-0 grid grid-rows-2 md:w-[180px] lg:w-[213px]">
                            <div className="float-left mx-auto md:mx-0">
                                <img src={savings} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-center md:text-center lg:text-left">
                                <span className="block text-2xl font-bold mb-2">Smart Savings</span>
                                <span className="block">
                                    Efficient talent matches reduce recruitment expenses.
                                </span>
                            </div>
                        </div>
                        <div className="border border-red-600 shadow-lg p-2 rounded-lg w-[328px] md:w- auto mx-auto md:mx-0 grid grid-rows-2 md:w-[180px] lg:w-[213px]">
                            <div className="float-left mx-auto md:mx-0">
                                <img src={talent} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-center md:text-center lg:text-left">
                                <span className="block text-2xl font-bold mb-2">Global Talent</span>
                                <span className="block">
                                    Tap into rich, worldwide talent reservoir.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 pt-6 text-center flex justify-center">
                        <button className="primary-button w-56">Connect with Talent</button>
                    </div>
                </div>
                <div className="hidden sm:none md:block w-[300px] md:w-[300px] lg:w-[485.7px]">
                    <img
                        decoding="async"
                        width="537"
                        src={MacBookImage}
                        alt=""
                        sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 768px, 100vw"
                    />
                </div>
            </div>
        </div>
    );
};

export default RefineTalent;
