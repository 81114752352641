import React from 'react';
import expandMore from '../../assets/images/expand_more.png';
import homeBackground from '../../assets/images/homeBackground.png';
import homeImage from '../../assets/images/homeImage.png';

const Hero = () => {
    const handleScroll = () => {
        const contactElement = document.getElementById('contact');
        if (contactElement) {
            window.scrollTo({
                top: contactElement.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div
            className="bg-cover bg-center relative z-10"
            style={{ backgroundImage: `url(${homeBackground})` }}>
            <div className="home-banner-container flex flex-col md:flex-row justify-between items-center">
                <div className="home-text-section mt-[78px] mr-4 mb-0 ml-4 flex flex-col items-start md:items-start gap-4 md:gap-10 max-w-lg">
                    <h1 className="primary-heading leading-[60px] text-[50px] lg:text-6xl font-semibold text-gray-800">
                        Unlock Your Potential with Lillup
                    </h1>
                    <p className="primary-text font-semibold mt-2 text-[24px] lg:text-[32px]  text-gray-800">
                        Where learning meets opportunity - Empowering growth for individuals and
                        companies.
                    </p>
                    <button
                        className="primary-button bg-blue-500 text-white py-2 px-4 rounded-md mt-[44px] md:mt-4"
                        onClick={handleScroll}>
                        Learn more
                    </button>
                </div>

                <div className="home-image-section relative w-full h-[430px] overflow-hidden">
                    <img
                        src={homeImage}
                        alt=""
                        className="home-img absolute h-[433.34px] left-[164px] opacity-100"
                        sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 768px, 100vw"
                    />
                </div>
            </div>

            <div className="scroll-container mt-[-225px] md:mt-0">
                <button style={{ cursor: 'pointer' }} onClick={handleScroll}>
                    <span className="scroll-text">Scroll</span>
                    <div className="scroll-icon-container">
                        <img src={expandMore} alt="" style={{ marginTop: '1px' }} />
                        <img src={expandMore} alt="" />
                        <img src={expandMore} alt="" />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Hero;
